import React from 'react'
import BaseComponent from './BaseComponent'
import { List, arrayMove } from "react-movable";
import _ from 'lodash'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import SingleImageUpload from './SingleImageUpload'
import SingleFileUpload from './SingleFileUpload'
import AddTerm from './AddTerm'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';

registerLocale('pl', pl)

const RemovableIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#555"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x-circle"
  >
    <title>Usuń</title>
    <circle cx="12" cy="12" r="10" />
    <line x1="15" y1="9" x2="9" y2="15" />
    <line x1="9" y1="9" x2="15" y2="15" />
  </svg>
);

const HandleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#555"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-move"
  >
    <polyline points="5 9 2 12 5 15" />
    <polyline points="9 5 12 2 15 5" />
    <polyline points="15 19 12 22 9 19" />
    <polyline points="19 9 22 12 19 15" />
    <line x1="2" y1="12" x2="22" y2="12" />
    <line x1="12" y1="2" x2="12" y2="22" />
  </svg>
);

const buttonStyles = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  border: 'none',
  margin: 0,
  padding: 0,
  width: 'auto',
  overflow: 'visible',
  cursor: 'pointer',
  background: 'transparent'
};

export default class Modules extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [] }
    this._bind('updateItem', 'onFileChange', 'removeItem', 'clearItem')
  }

  updateItem(index, type, value) {
    let items = [...this.props.items]
    if(index) { 
      items[index][type] = value
      this.props.changeModuleItems(items)
    }
  }

  removeItem(index) {
    let items = [...this.props.items]
    items.splice(index, 1)
    this.props.changeModuleItems(items)
  }

  clearItem(index, value) {
    let items = [...this.props.items]
    items[index] = {type: value, custom: ""}
    this.props.changeModuleItems(items)
  }

  onFileChange(file, name, index) {
    let files = this.state.files
    if (_.filter(files, { name: name }).length < 1) {
      files.push(file)
    }
    this.setState({ files: files })
    this.updateItem(index, "fileName", [name])
    this.props.onFilesChange(files)
    //console.log(file, name)
  }

  render () {
    //console.log(this.state)
    return (
      <div className="modules">
        <List
          values={this.props.items || []}
          onChange={({ oldIndex, newIndex }) =>
            this.props.changeModuleItems(arrayMove(this.props.items, oldIndex, newIndex))
          }
          renderList={({ children, props }) => <ul {...props}>{children}</ul> }
          renderItem={({ value, props, isDragged, isSelected }) => { 
            let component = null
            if (value.type === "text" || value.type === "intro" || value.type === "disclaimer" || value.type === "how_to_use_activity") {
              component = <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                      toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                      heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                    } }
                    data={ value.custom }
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />
            } else if (value.type === "description_fail" || value.type === "description_success") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  title: ""
                }
              }

              component = <div>
                { value.type === "description_success" ? <h4>Opis - poprawne wykonanie zadania</h4> : <h4>Opis - błędne wykonanie zadania</h4> }
                <p>Tytuł</p>
                <input type="text" value={ val.metadata.title } onChange={ (e) => { val.metadata.title = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                <p>Opis</p>
                <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                      toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                      heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                    } }
                    data={ value.custom }
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />
              </div>
            } else if (value.type === "activation_value") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  title: ""
                }
              }

              component = <div>
                <p>Tytuł</p>
                <input type="text" value={ val.metadata.title } onChange={ (e) => { val.metadata.title = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                <p>Opis</p>
                <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                      toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                      heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                    } }
                    data={ value.custom }
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />
              </div>
            } else if(value.type === "columns") {
              let val = value
              if (!val.columns) {
                val.columns = [{custom: ""}, {custom: ""}]
              }

              component = (
                <div>
                  <h4>Kolumny</h4>
                  <div className="flex-columns">
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[0].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          val.columns[0].custom = data
                          // console.log( { event, editor, data } );
                          this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[1].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        val.columns[1].custom = data
                        // console.log( { event, editor, data } );
                        this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                  </div>
                </div>
              )
            } else if(value.type === "columns-3") {
              let val = value
              if (!val.columns) {
                val.columns = [{custom: ""}, {custom: ""}, {custom: ""}]
              }

              component = (
                <div>
                  <h4>Kolumny</h4>
                  <div className="flex-columns three">
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[0].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          val.columns[0].custom = data
                          // console.log( { event, editor, data } );
                          this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[1].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        val.columns[1].custom = data
                        // console.log( { event, editor, data } );
                        this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[2].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        val.columns[2].custom = data
                        // console.log( { event, editor, data } );
                        this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                  </div>
                </div>
              )
            } else if (value.type === "image-text") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  layout: "left",
                  alt: "",
                  imgDesc: ""
                }
              }

              component = <div>
                  <h4>Układ</h4>
                  <select onChange={ (e) => { val.metadata.layout = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.layout }>
                      <option value="left">Tekst po lewej</option>
                      <option value="right">Tekst po prawej</option>
                  </select>
                  <h4>Zawartość (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Grafika</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Podpis obrazka</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "image") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  layout: "left",
                  alt: "",
                  imgDesc: ""
                }
              }

              component = <div>
                  <h4>Grafika</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Podpis obrazka</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "partner_info") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  alt: ""
                }
              }

              component = <div>
                  <h4>Zawartość (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraf', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Logo</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Tekst alternatywny</p>
                  <input type="text" value={ val.metadata.alt } onChange={ (e) => { val.metadata.alt = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "address") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  name: "",
                  street: "",
                  buildingNumber: "",
                  apartmentNumber: "",
                  city: "",
                  postalCode: "",
                  latitude: "",
                  longitude: ""
                }
              }

              component = <div>
                  <h4>Zawartość</h4>
                  <p className="input-desc">Nazwa</p>
                  <input type="text" value={ val.metadata.name } onChange={ (e) => { val.metadata.name = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <div className="flex">
                    <div>
                      <p className="input-desc">Ulica</p>
                      <input type="text" value={ val.metadata.street } onChange={ (e) => { val.metadata.street = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                    <div>
                      <p className="input-desc">Numer budynku</p>
                      <input type="text" value={ val.metadata.buildingNumber } onChange={ (e) => { val.metadata.buildingNumber = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                    <div>
                      <p className="input-desc">Numer lokalu</p>
                      <input type="text" value={ val.metadata.apartmentNumber } onChange={ (e) => { val.metadata.apartmentNumber = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                  </div>
                  <div className="flex">
                    <div>
                      <p className="input-desc">Miasto</p>
                      <input type="text" value={ val.metadata.city } onChange={ (e) => { val.metadata.city = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                    <div>
                      <p className="input-desc">Kod pocztowy</p>
                      <input type="text" value={ val.metadata.postalCode } onChange={ (e) => { val.metadata.postalCode = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                  </div>
                  <div className="flex">
                    <div>
                      <p className="input-desc">Szerokość geograficzna</p>
                      <input type="text" value={ val.metadata.latitude || 0 } onChange={ (e) => { val.metadata.latitude = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                    <div>
                      <p className="input-desc">Długość geograficzna</p>
                      <input type="text" value={ val.metadata.longitude || 0 } onChange={ (e) => { val.metadata.longitude = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                  </div>
                </div>
            } else if (value.type === "video") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  desc: "",
                  name: ""
                }
              }

              component = <div>
                  <h4>Plik</h4>
                  <p>Typ pliku: MP4, maksymalny rozmiar: 10MB</p>
                  <SingleFileUpload ident={ "video" } API={ this.props.API } file={ val.metadata.name } onChange={ (file) => {
                    val.file.uuid = file.uuid
                    val.metadata.name = file.name
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Tytuł video</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "how_to_use") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  name: "",
                  desc: ""
                }
              }

              component = <div>
                  <h4>Plik</h4>
                  <p>Typ pliku: pdf</p>
                  <AddTerm restricted={false} API={ this.props.API } file={ val.metadata.name } onChange={ (file, fileName) => {
                    val.file = file
                    val.metadata.name = fileName
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Nazwa wyświetlana</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "terms_pdf") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  name: "",
                  desc: ""
                }
              }

              component = <div>
                  <h4>Plik</h4>
                  <p>Typ pliku: pdf</p>
                  <AddTerm restricted={false} API={ this.props.API } file={ val.metadata.name } onChange={ (file, fileName) => {
                    val.file = file
                    val.metadata.name = fileName
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Nazwa wyświetlana</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "article-file") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  desc: "",
                  name: ""
                }
              }

              component = <div>
                  <h4>Plik</h4>
                  <SingleFileUpload ident={ "article" } API={ this.props.API } file={ val.metadata.name } onChange={ (file) => {
                    val.file.uuid = file.uuid
                    val.metadata.name = file.name
                    console.log(val)
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Opis pliku</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "offer-file") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  desc: "",
                  name: ""
                }
              }

              component = <div>
                  <h4>Plik</h4>
                  <SingleFileUpload ident={ "offer" } API={ this.props.API } file={ val.metadata.name } onChange={ (file) => {
                    val.file.uuid = file.uuid
                    val.metadata.name = file.name
                    console.log(val)
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Opis pliku</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "thumbnail-mobile" || value.type === "thumbnail-desktop") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  alt: "",
                  imgDesc: ""
                }
              }

              component = <div>
                  <h4>Grafika</h4>
                  <SingleImageUpload ident={ "thumbnail" } API={ this.props.API } image={ val.file.uuid } onChange={ (img) => {
                    val.file.uuid = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Tekst alternatywny</p>
                  <input type="text" value={ val.metadata.alt } onChange={ (e) => { val.metadata.alt = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Podpis obrazka</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "button") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  name: ""
                }
              }

              component = <div>
                  <h4>Adres URL</h4>
                  <p className="input-desc">Jeżeli adres posiada http lub https na początku, będzie linkiem zewnętrznym</p>
                  <input type="text" value={ value.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                  <h4>Tytuł buttonu</h4>
                  <input type="text" value={ val.metadata.name } onChange={ (e) => { val.metadata.name = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "external_url") {
              component = <div>
                  <h4>Adres URL</h4>
                  <p className="input-desc">Jeżeli adres posiada http lub https na początku, będzie linkiem zewnętrznym</p>
                  <input type="text" value={ value.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                </div>
            } else if (value.type === "image") {
              let val = value
              if (!val.custom) {
                val.custom = {}
              }

              component = <div>
                  <h4>Grafika</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.custom.image } onChange={ (img) => { val.custom.image = img; this.updateItem(props.key, "custom", val.custom) } }/>
                  <p className="input-desc">Tekst alternatywny</p>
                  <input type="text" value={ val.custom.altImage } onChange={ (e) => { val.custom.altImage = e.target.value; this.updateItem(props.key, "custom", val.custom) } }/>
                </div>
            }
            
            return <li {...props} style={{
              ...props.style,
              padding: '1.5em',
              margin: '0.5em 0em',
              listStyleType: 'none',
              cursor: isDragged ? 'grabbing' : 'grab',
              border: '2px solid #CCC',
              color: '#333',
              borderRadius: '5px',
              fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
              backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF',
              display: value.trash || value.singleUse ? "none" : "block",
              zIndex: 100-props.key
            }} onKeyDown={ null } data-moduletype={value.type} data-moduleindex={props.key}>
              <h4>Typ modułu</h4>
              <p className="input-desc">Wybierz typ modułu</p>
              <select value={ value.type } onChange={ (e) => { this.clearItem(props.key, e.target.value) } }>
                <option value="">Wybierz typ modułu</option>
                { this.props.availableModules.map((module, j) => {
                  return <option value={module} key={ j }>{ module }</option>
                }) }
                {/* <option value="intro">intro</option>
                <option value="text">text</option>
                <option value="columns">columns</option>
                <option value="image-text">image-text</option>
                <option value="disclaimer">disclaimer</option>
                <option value="agenda">agenda</option>
                <option value="thumbnail-mobile">thumbnail-mobile</option>
                <option value="thumbnail-desktop">thumbnail-desktop</option>
                <option value="article-file">article-file</option>
                <option value="button">button</option> */}
              </select>
              <h4>Zawartość modułu</h4>
              <p className="input-desc">Uzupełnij zawartość modułu</p>
              { component }
              <button
                onClick={(e) => {
                  e.preventDefault()
                  this.removeItem(props.key)
                }}
                style={buttonStyles}
              >
                <RemovableIcon />
              </button>
              <button
                data-movable-handle
                style={{
                  ...buttonStyles,
                  cursor: isDragged ? 'grabbing' : 'grab',
                  marginRight: '3em'
                }}
                tabIndex={-1}
              >
                <HandleIcon />
              </button>
            </li> }
          }
        />
        <button className="btn" onClick={ (e) => { e.preventDefault(); this.props.addModule() } }><span>Dodaj moduł</span></button>
      </div>
    )
  }
}
